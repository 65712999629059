import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'supertokens-web-js/types';

export const userInfo = signal<User | undefined>(undefined);

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) { }

    public async getUserInfo(): Promise<void> {
        await firstValueFrom(this.http.get<User>(`${environment.API}get-user-info`).pipe(
            tap((user) => {
                userInfo.set(user);
                console.log(userInfo());
            }),
            take(1)));
    }

    public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        await firstValueFrom(this.http.post(`${environment.API}profile/changepwd`, { oldPassword, newPassword }).pipe(
            take(1)));
    }

    public async fixRole(): Promise<void> {
        await firstValueFrom(this.http.post(`${environment.API}profile/fixrole`, { role: 'biz' }).pipe(
            take(1)));
    }


}
